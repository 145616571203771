import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FieldRenderProps } from "react-final-form";
import { themeColor } from "../../styles-utils";
import { RequiredSpan } from "../../../../src/components/Checkout/Information/styled";
import { isBrowser } from "../../../lib/isBrowser";
import { useStore } from "@/lib/storeData/StoreContext";
import { LazyInputPhone } from "../LazyElements/react-phone-number-input";

export type PhoneInput =
  | {
      value: string;
      isValid: boolean;
    }
  | undefined;

type OtherProps = {
  dataTest?: string;
  className?: string;
  setPhoneValue?: (value: any) => void;
};
export interface InputPhoneProps
  extends FieldRenderProps<PhoneInput, HTMLElement, PhoneInput>,
    OtherProps {}

const InputPhone: React.FC<InputPhoneProps> = ({
  input,
  meta,
  dataTest,
  className,
  setPhoneValue,
  ...props
}) => {
  const { storeId } = useStore();

  const cachedCountry = isBrowser
    ? (localStorage.getItem("default-country") as string)
    : undefined;

  const [defaultCountry, setDefaultCountry] = useState<string | undefined>(
    cachedCountry
  );

  useEffect(() => {
    // if the store is petlinekwt then the default country should be Kuwait
    if (storeId === "Store_ckwxn9jl1021b01kkesvo0edh") {
      localStorage.setItem("default-country", "KW");
      setDefaultCountry("KW");
      return;
    }

    if (!defaultCountry) {
      fetch("https://ipinfo.io/json")
        .then((res) => res.json())
        .then((res) => {
          localStorage.setItem("default-country", res?.country);
          setDefaultCountry(res?.country);
        })
        .catch(() => {
          localStorage.setItem("default-country", "EG");
          setDefaultCountry("EG");
        });
    }
  }, [defaultCountry]);

  return (
    <>
      <StyledInput
        {...input}
        className={`${className} ${
          meta?.error && meta?.touched ? "invalid" : ""
        }`}
        data-test={dataTest}
        countryOptionsOrder={["EG", "SA", "AE", "KW", "|", "..."]}
        international={true}
        defaultCountry={defaultCountry as any}
        value={input?.value?.value}
        onChange={(value, isValid) => {
          setPhoneValue?.({
            value,
            isValid,
          });
          if (props?.onChange) {
            props?.onChange(value);
          }
          input?.onChange({
            value,
            isValid,
          });
        }}
      />
      {meta?.error && meta?.touched && (
        <RequiredSpan>{meta?.error}</RequiredSpan>
      )}
    </>
  );
};

export default InputPhone;

const StyledInput = styled(LazyInputPhone)`
  margin-top: 0.375rem;
  width: 100%;
  position: relative;
  direction: ltr;
  .PhoneInputCountry {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-inline-start: 10px;
  }

  .PhoneInputInput {
    padding-inline-start: 55px;
  }

  .PhoneInputCountrySelectArrow {
    margin-inline-start: 8px;
    color: ${({ theme }) => theme.colors.primary};
    border-bottom-width: 2px;
    border-right-width: 2px;
    width: 0.4em;
    height: 0.4em;
    opacity: 1;
  }

  &.invalid > input {
    border: 1px solid #d21c1c;
  }

  input {
    flex: 1 0 auto;
    background: ${({ theme }) => theme.bg.default};
    width: 100%;
    font-size: 16px;
    border: 1px solid ${({ theme }) => theme.bg.inactive};
    border-radius: 0.25rem;
    outline: none;
    padding: 0.5rem 0.75rem;
    font-weight: 600;
    &::placeholder {
      color: ${({ theme }) => theme.text.default};
      opacity: 0.5;
    }
    &::-webkit-input-placeholder {
      color: ${({ theme }) => theme.text.default};
      opacity: 0.5;
    }
    &:-moz-placeholder {
      color: ${({ theme }) => theme.text.default};
      opacity: 0.5;
    }
    &:-ms-input-placeholder {
      color: ${({ theme }) => theme.text.default};
      opacity: 0.5;
    }
    &:focus {
      border: 1px solid ${themeColor("primary")};
    }
    @media (min-width: 768px) {
      font-size: 14px;
    }
  }
`;

/**
 *
 *
 */

export function createDefaultPhoneInput(
  number: string | undefined | null
): PhoneInput {
  if (!number) return undefined;
  return { value: number, isValid: true };
}
